import React from "react";
import '../sass/homeNews.css';

import CustomH2 from "./technicalComponents/CustomH2";
import CustomArticle from "./technicalComponents/CustomArticle";

import CustomButton from "./technicalComponents/CustomButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import article22 from "../img/articles/article22.webp";
import article23 from "../img/articles/article23.webp";
import article24 from "../img/articles/article24.webp";

const HomeNews = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/news');
    }
    return(
        <section className="newsSection">
            <CustomH2 text="News"/>
            <div className="newsSection-grid">
                <CustomArticle
                    date="2025-02-23"
                    title={t("art_24_title")}
                    image={article24}
                    isLarge={true}
                    linkEN="https://mailchi.mp/c446de68a35a/maciej-gadysz-on-the-podium-again?e=9eec90125b"
                    linkPL="https://mailchi.mp/106ae40df9f1/maciej-gadysz-ponownie-na-podium?e=9eec90125b"
                />
                <CustomArticle
                    date="2025-02-20"
                    title={t("art_23_title")}
                    image={article23}
                    isLarge={false}
                    linkEN="https://mailchi.mp/4f247dd5abff/maciej-gadysz-ready-to-race-again-in-portimo?e=9eec90125b"
                    linkPL="https://mailchi.mp/a3e693432ee6/maciej-gadysz-gotowy-na-wycigi-w-portimao?e=9eec90125b"
                />
                <CustomArticle
                    date="2025-02-09"
                    title={t("art_22_title")}
                    image={article22}
                    isLarge={false}
                    linkEN="https://mailchi.mp/7b253839a95e/maciej-gadysz-wins-on-his-formula-3-debut?e=9eec90125b"
                    linkPL="https://mailchi.mp/94b08317395b/maciej-gadysz-wygrywa-w-debiucie-w-bolidzie-f3?e=9eec90125b"
                />
            </div>
            <div className="buttonContainer">
                <CustomButton label={t("button_01")} onClick={handleClick}/>
            </div>
        </section>
    );
};

export default HomeNews;