import React from "react";
import '../sass/partnersSection.css';
import CustomH2 from "./technicalComponents/CustomH2";

import orlen from '../img/partners/orlen.svg';
import brukbet from '../img/partners/bruk-bet.svg';
import maxton from '../img/partners/MaxtonDesign.svg';
import pzm from '../img/partners/pzm.svg';
import malopolska from '../img/partners/malopolskaLogo.svg';
import solbet from '../img/partners/SOLBET.svg';
import {useTranslation} from "react-i18next";


const HomePartners = () => {

    const {t} = useTranslation();

    return(
        <section className="partnersSection">
            <div className="partnersText">
                <CustomH2 text={t("partners")}/>

            </div>
            <div className="partnersSection-container">
                <div className="imgWrapper">
                    <a href="https://www.orlen.pl/pl" target="_blank" rel="noopener noreferrer">
                        <img className="orlen" src={orlen} alt="orlen"/>
                    </a>
                    <a href="https://www.bruk-bet.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="brukbet" src={brukbet} alt="Bruk-Bet"/>
                    </a>
                    <a href="https://maxtondesign.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="maxton" src={maxton} alt="Maxton Design"/>
                    </a>
                    <a href="https://www.solbet.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="solbet" src={solbet} alt="solbet"/>
                    </a>
                    {/*<a href="https://www.gladysz-group.pl/" target="_blank" rel="noopener noreferrer">*/}
                    {/*    <img className="gladyszisynowie" src={gladyszisynowie} alt="Gładysz i Synowie"/>*/}
                    {/*</a>*/}
                    <a href="https://www.malopolska.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="malopolska" src={malopolska} alt="Małopolski urząd marszałkowski"/>
                    </a>
                    <a href="https://pzm.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="smallerImg esmallerImg pzm" src={pzm} alt="PZM"/>
                    </a>
                </div>
            </div>
        </section>
);
};

export default HomePartners;